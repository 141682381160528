// Only continue if polyfills are actually needed
import {loadJS} from "./utils";

if (!('scrollBehavior' in document.documentElement.style)) {
    loadJS('seamless-scroll-polyfill.min.js', () => {
        if (window.seamless) {
            window.seamless.polyfill();
        }
    });
}
