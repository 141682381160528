import {loadJS} from "./utils";
import FuseNS from "../@types/fuse";

/**
 * our search records
 */

export enum SearchArea {
    All = 0,
    Blog = 1,
    Site = 2,
    Guides = 3,
    Docs = 4,
    Api = 5,
    ReleaseNotes = 6
}

export const SearchAreaEnums: { [key: string]: SearchArea } = {
    'all': SearchArea.All,
    'blog': SearchArea.Blog,
    'site': SearchArea.Site,
    'guides': SearchArea.Guides,
    'docs': SearchArea.Docs,
    'api': SearchArea.Api,
    'releasenotes': SearchArea.ReleaseNotes
}

export const SearchAreaIDs = Object.keys(SearchAreaEnums);

export interface SearchRequest {
    query: string;
    area: SearchArea;
    areaId: string;
    areaTitle: string;
}

export interface SearchDoc {
    u: string; // url
    t: string; // title
    h: string; // headers
    b: string; // body
    l?: number; // english only
    a: SearchArea; // area
}

type SearchResult = FuseNS.FuseResult<SearchDoc>;

/**
 * Search Helper Class
 */

export class Search {
    currentSearchArea: SearchArea = SearchArea.All;
    tilesElement: HTMLElement | undefined;
    inputElement: HTMLInputElement;
    lang_entries: string;
    lang_no_results: string;
    lang_is_searching_text: string;
    lang_title: string;
    lang: string;
    langAreas: { [id: string]: string } = {};
    private fuse?: FuseNS.Fuse<SearchDoc>;
    private loading: boolean;
    private readonly countElement: HTMLElement;
    private readonly loaderElement: HTMLElement;
    private readonly loaderSpanElement: HTMLElement;
    private readonly resultsElement: HTMLElement;
    private readonly paginationElement: HTMLElement;
    private readonly sectionElement: HTMLElement;
    private readonly searchAreaNames: Array<string> = [];
    private queryAfterLoading?: { request: SearchRequest; callback: (request: SearchRequest, result: Array<SearchResult>) => void };

    constructor(private parent: Element, private onSearch: (request: SearchRequest) => void) {
        this.lang = document.getElementsByTagName('html')[0].getAttribute('lang') || 'en';
        this.inputElement = parent.querySelector('input.search-input') as HTMLInputElement;
        this.lang_entries = this.inputElement.dataset.langEntries || 'Entries';
        this.lang_no_results = this.inputElement.dataset.langNoResults || 'No results';
        this.lang_title = this.inputElement.dataset.langTitle || '';
        this.lang_is_searching_text = this.inputElement.dataset.langIsSearching || 'Searching in';
        this.tilesElement = parent.querySelector('.topics') as HTMLElement;
        const anchors = parent.querySelectorAll('.search-sections-tabs li button');
        anchors.forEach((el: HTMLElement) => {
            const id = el.dataset.section || 'all';
            const area = SearchAreaEnums[id] || 0;
            this.langAreas[id] = el.textContent || id;
            this.searchAreaNames[SearchAreaEnums[id]] = el.innerText
            el.addEventListener('click', () => {
                this.setCurrentSearch(area);
                this.search();
            });
        });

        const iconElement = parent.querySelector('.icon-search');
        iconElement?.addEventListener('click', () => this.search());
        this.inputElement.addEventListener('search paste', () => this.search());
        this.inputElement.addEventListener('keypress', event => {
            const keycode = event.keyCode || event.which;
            if (keycode === 13) {
                this.search();
            }
        });

        this.sectionElement = document.createElement('div');
        this.sectionElement.classList.add('search-section')

        const sectionHead = document.createElement('div');
        sectionHead.classList.add('search-section-head');
        this.sectionElement.append(sectionHead);

        this.countElement = document.createElement('div');
        this.countElement.classList.add('search-result-count');
        sectionHead.append(this.countElement);

        this.loaderElement = document.createElement('div');
        this.loaderElement.classList.add('search-section-loader');
        this.loaderElement.innerHTML = '<svg class="circular"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"></circle></svg>'
        this.sectionElement.append(this.loaderElement);

        this.loaderSpanElement = document.createElement('span');
        this.loaderElement.append(this.loaderSpanElement);

        this.resultsElement = document.createElement('ul');
        this.resultsElement.classList.add('search-section-results')
        this.sectionElement.append(this.resultsElement);
        this.paginationElement = document.createElement('div');
        this.paginationElement.classList.add('search-section-pagination')
        this.sectionElement.append(this.paginationElement);

        const sectionsParent = parent.querySelector('.search-sections') as HTMLElement;
        sectionsParent.appendChild(this.sectionElement);

        this.setCurrentSearchLabel(this.currentSearchArea);
    }

    private clearResults() {
        while (this.resultsElement?.firstChild) {
            this.resultsElement.removeChild(this.resultsElement.firstChild)
        }
        while (this.paginationElement?.firstChild) {
            this.paginationElement.removeChild(this.paginationElement.firstChild)
        }
    }

    private setCurrentSearchLabel(area: SearchArea) {
        this.loaderSpanElement.textContent = `${this.lang_is_searching_text} ${this.langAreas[SearchAreaIDs[area]] || SearchAreaIDs[area]}`;

    }

    private static reportMatomo(request: SearchRequest, count: number) {
        const paq = window._paq;
        if (paq) {
            // https://matomo.org/docs/site-search/
            paq.push(['trackSiteSearch', request.query, request.areaId, count]);
        }
    }

    private toggleTiles(show: boolean) {
        if (this.tilesElement) {
            this.tilesElement.style.display = show ? 'block' : 'none';
        }
        if (this.sectionElement) {
            this.sectionElement.style.display = !show ? 'block' : 'none';
        }
    }

    private toggleLoader(show: boolean) {
        if (this.loaderElement) {
            this.loaderElement.style.display = show ? 'block' : 'none';
        }
    }

    private fuseSearch(request: SearchRequest) {
        if (this.queryAfterLoading && this.fuse && this.queryAfterLoading.request === request) {
            let result: Array<SearchResult> = [];
            try {
                result = this.fuse.search(`'"${request.query}"`);
                if (request.area !== SearchArea.All) {
                    result = result.filter(r => r.item.a === request.area);
                }
            } catch (e) {
                console.error(e);
            }
            this.queryAfterLoading.callback(request, result);
            this.queryAfterLoading = undefined;
        }
    }

    private buildFuse(data: Array<SearchDoc>) {
        const opts: FuseNS.IFuseOptions<SearchDoc> = {
            location: 0,
            distance: 0,
            threshold: 0,
            useExtendedSearch: true,
            findAllMatches: true,
            ignoreLocation: true,
            ignoreFieldNorm: true,
            minMatchCharLength: 0,
            includeMatches: true,
            keys: [
                {name: "t", weight: 4},
                {name: "s", weight: 5},
                {name: "h", weight: 3},
                {name: "b", weight: 2}
            ]
        }
        this.fuse = new window.Fuse(data, opts);
    }

    private doSearch(request: SearchRequest, callback: (request: SearchRequest, result: Array<SearchResult>) => void) {
        this.queryAfterLoading = {request, callback};
        if (!this.fuse) {
            if (this.loading) {
                return;
            }
            this.loadData(() => {
                this.fuseSearch(request);
            })
        } else {
            this.fuseSearch(request);
        }
    }

    private setCountText(text: string) {
        if (this.countElement) {
            this.countElement.textContent = text;
        }
    }

    private setResultsPageVisible(page: number, elements: Array<HTMLElement>) {
        const start = 10 * (page - 1);
        const end = (10 * page) - 1;
        elements.forEach((element, index) => {
            const isVisible = (index >= start && index <= end);
            element.style.display = isVisible ? 'block' : 'none';
        });
    }

    private buildParagraphsHTML(match: FuseNS.FuseResultMatch, result: SearchResult): Array<Array<{ text: string; strong?: boolean }>> {
        let body = result.item.b;
        const highlights = match.indices.slice(0);
        const beginMarker = '🐻';
        const endMarker = '🐼';
        highlights.sort((a, b) => b[0] - a[0]);
        for (const highlight of highlights) {
            const start = highlight[0];
            const end = highlight[1] + 1;
            body = body.slice(0, start) + beginMarker + body.slice(start, end) + endMarker + body.slice(end);
        }
        const lines = body.split('\n');
        const rows: Array<Array<{ text: string; strong?: boolean }>> = [];
        for (const line of lines) {
            const row: Array<{ text: string; strong?: boolean }> = [];
            const parts = line.split(beginMarker);
            parts.forEach(p => {
                const highlight = p.split(endMarker);
                if (highlight.length > 1) {
                    row.push({text: highlight[0], strong: true});
                    row.push({text: highlight[1]});
                } else {
                    row.push({text: p});
                }
            });
            rows.push(row);
        }
        const filtered: Array<Array<{ text: string; strong?: boolean }>> = [];
        for (const row of rows) {
            const count = row.filter(c => c.strong).length;
            if (count === 0) {
                if (filtered.length > 0 && filtered[filtered.length - 1][0].text !== '…') {
                    filtered.push([{text: '…'}]);
                }
            } else {
                filtered.push(row);
            }
        }
        if (filtered.length > 0 && filtered[filtered.length - 1][0].text === '…') {
            filtered.pop();
        }
        return filtered;
    }

    private resultPreviewHTML(result: SearchResult, previewElement: HTMLElement): void {
        const bodyMatch = (result.matches || []).find(m => m.key === 'b');
        if (!bodyMatch) {
            return;
        }
        const paragraphs = this.buildParagraphsHTML(bodyMatch, result);
        const maxParagraphs = 3;
        if (paragraphs.length > maxParagraphs) {
            const toggleAnchorElement = document.createElement('a');
            toggleAnchorElement.classList.add('toggle-preview');
            toggleAnchorElement.setAttribute('title', 'Show all');
            toggleAnchorElement.setAttribute('aria-expanded', 'false');
            toggleAnchorElement.setAttribute('role', 'button');
            toggleAnchorElement.addEventListener('click', () => {
                previewElement.classList.toggle('expanded');
                const expanded = previewElement.classList.contains('expanded');
                toggleAnchorElement.setAttribute('title', expanded ? 'Limit' : 'Show all');
                toggleAnchorElement.setAttribute('aria-expanded', `${expanded}`);
            });
            previewElement.append(toggleAnchorElement);
        }
        paragraphs.forEach((p, lineNr) => {
            const paragraphElement = document.createElement('p');
            if (lineNr >= maxParagraphs) {
                paragraphElement.classList.add('expanded-only');
            }
            for (const item of p) {
                if (item.strong) {
                    const strongElement = document.createElement('strong');
                    strongElement.innerText = item.text;
                    paragraphElement.append(strongElement);
                } else {
                    paragraphElement.append(document.createTextNode(item.text));
                }
            }
            previewElement.append(paragraphElement);
        });
    }

    private buildSearchResultHTML(result: SearchResult, request: SearchRequest): HTMLElement {
        const item: SearchDoc = result.item;
        const liElement = document.createElement('li');
        liElement.classList.add('result');

        const titleElement = document.createElement('div');
        titleElement.classList.add('result-title');
        liElement.append(titleElement);

        const titleLinkElement = document.createElement('a');
        titleLinkElement.href = item.u;
        titleLinkElement.innerText = item.t;
        titleElement.append(titleLinkElement);

        if (request.area === SearchArea.All) {
            const badgeElement = document.createElement('span');
            badgeElement.classList.add('badge', 'bg-secondary', 'hidden-for-mobile');
            badgeElement.textContent = this.searchAreaNames[item.a] || SearchAreaIDs[item.a];
            titleElement.append(badgeElement);
        }
        if (item.l === 1) {
            const badgeLangElement = document.createElement('span');
            badgeLangElement.classList.add('badge', 'bg-primary', 'hidden-for-mobile');
            badgeLangElement.textContent = 'EN';
            titleElement.append(badgeLangElement);
        }

        const urlElement = document.createElement('a');
        urlElement.classList.add('result-url');
        urlElement.href = item.u;
        urlElement.innerText = `https://www.openproject.org${item.u}`;
        urlElement.tabIndex = -1;
        liElement.append(urlElement);

        const previewElement = document.createElement('div');
        previewElement.classList.add('result-preview');
        this.resultPreviewHTML(result, previewElement);
        liElement.append(previewElement);

        return liElement;
    }

    private showResults(request: SearchRequest, results: Array<SearchResult>): void {
        this.toggleLoader(false);
        this.toggleTiles(false);
        this.clearResults();
        this.setCountText(`${this.lang_entries}: ${results.length}`);
        if (!this.resultsElement || !this.paginationElement) {
            return;
        }
        if (results.length === 0) {
            const liElement = document.createElement('li');
            liElement.classList.add('no-result');
            liElement.innerText = `${this.lang_no_results} "${request.query}"`;
            this.resultsElement.append(liElement);
            return;
        }
        try {
            const elements: Array<HTMLElement> = [];
            results.forEach(result => {
                const liElement = this.buildSearchResultHTML(result, request);
                elements.push(liElement);
                this.resultsElement.append(liElement);
            });
            if (elements.length > 10) {
                this.setResultsPageVisible(1, elements);
                const pages = Math.ceil(elements.length / 10);
                const pageElements: Array<HTMLElement> = [];
                for (let i = 1; i <= pages; i++) {
                    const pageElement = document.createElement('button');
                    pageElement.classList.add('page-button');
                    if (i === 1) {
                        pageElement.classList.add('active');
                    }
                    pageElement.innerText = `${i}`;
                    pageElement.addEventListener('click', () => {
                        this.setResultsPageVisible(i, elements);
                        pageElements.forEach(pe => {
                            pe.classList.remove('active');
                        })
                        pageElement.classList.add('active');
                    })
                    pageElements.push(pageElement);
                    this.paginationElement.append(pageElement);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    private loadData(callback: () => void): void {
        this.loading = true;
        window.fetch(`/assets/search/search-${this.lang}.json`)
            .then(response => {
                response.json().then(data => {
                    this.buildFuse(data);
                    this.loading = false;
                    callback();
                }).catch(e => {
                    console.error(e);
                });
            })
            .catch(e => {
                console.error(e);
            });
    }

    public preloadIndex() {
        if (!this.loading) {
            this.loadData(() => {
                // console.log('Preloaded');
            });
        }
    }

    public search() {
        const query = (this.inputElement.value || '').toString();
        const request: SearchRequest = {
            query,
            area: this.currentSearchArea,
            areaId: SearchAreaIDs[this.currentSearchArea],
            areaTitle: this.langAreas[SearchAreaIDs[this.currentSearchArea]] || SearchAreaIDs[this.currentSearchArea]
        };
        this.onSearch(request);
        if (query.length === 0) {
            this.clearResults();
            this.toggleTiles(true);
            this.toggleLoader(false);
            return;
        }
        this.toggleTiles(false);
        this.toggleLoader(true);
        this.doSearch(request, (request, result) => {
            this.showResults(request, result);
            Search.reportMatomo(request, result.length);
        });
    }

    public setCurrentSearch(area: SearchArea) {
        this.currentSearchArea = area;
        this.setCurrentSearchLabel(area);
        this.clearResults();
    }

    static loadJS(callback: () => void) {
        loadJS('fuse.min.js', callback);
    }

}
