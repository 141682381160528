function openTabHash(): void {
    const hash = document.location.hash;
    if (hash?.length > 1) {
        const tabLinkElement = document.querySelector(`a[href="${hash}"][role="tab"]`);
        if (tabLinkElement) {
            const tab = new bootstrap.Tab(tabLinkElement)
            tab?.show();
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    openTabHash(); // for the initial page load
    window.addEventListener("hashchange", openTabHash, false); // for later changes to url
});
