window.addEventListener('load', () => {
    document.querySelectorAll('video[data-poster-png]')
        .forEach((video: HTMLElement) => {
            const poster = video.getAttribute('poster');
            if (poster) {
                const png = video.dataset.posterPng || poster;
                const img = new Image();
                img.onerror = function () {
                    video.setAttribute('poster', png)
                };
                img.src = poster;
            }
        });
});
