document.addEventListener('DOMContentLoaded', function() {
	document.querySelectorAll('[data-set-focus-id]')
		.forEach((element: HTMLElement) => {
			element.addEventListener('click', () => {
				const targetId = element.dataset.setFocusId;
				if (targetId) {
					const target = document.getElementById(targetId);
					if (target) {
						target.focus();
					}
				}
			});
		});
});
