import {Search} from './fuse-search';

function initHeaderInterSection() {
    const headerEl = document.querySelector('.header')
    const sentinelEl = document.querySelector('.header-sentinel')
    const className = 'header-scrolled';
    if (headerEl && sentinelEl) {
        const handler = (entries) => {
            if (!entries[0].isIntersecting) {
                headerEl.classList.add(className);
            } else {
                headerEl.classList.remove(className);
            }
        };
        const observer = new window.IntersectionObserver(handler);
        observer.observe(sentinelEl)
    }
}

function initHeaderSearch() {
    let search: Search | undefined;
    let isActive: boolean = false;

    const initSearch = (element: Element) => {
        if (search) {
            return;
        }
        Search.loadJS(() => {
            search = new Search(element, () => {
            });
        });
    };

    const toggleSearch = (element: Element) => {
        if (!element.classList.contains('active')) {
            initSearch(element);
            setTimeout(() => {
                search?.inputElement.focus();
            }, 600);
            isActive = true;
        } else {
            isActive = false;
        }
        element.classList.toggle('active');
        document.body.classList.toggle('no-scroll');
    };

    const headerSearchElement = document.querySelector('.header-search');
    const headerSearchToggleElements = document.querySelectorAll('.header-search-toggle');
    if (headerSearchElement) {
        headerSearchToggleElements.forEach(el => {
            el.addEventListener('click', () => {
                toggleSearch(headerSearchElement);
            })
        });
        document.addEventListener('keyup', event => {
            const keycode = event.keyCode || event.which;
            if (isActive && keycode == 27) {
                toggleSearch(headerSearchElement);
            }
        });
    }
}

function initHeaderKeyboardMenu() {
    const headerDropdownToggleElements = document.querySelectorAll('.header .dropdown-toggle');
    const headerDropdownMenues: Array<HTMLElement> = [];

    headerDropdownToggleElements.forEach((element: HTMLElement) => {
        if (element.dataset.bsTarget) {
            const menu = document.querySelector(element.dataset.bsTarget) as HTMLElement;
            if (menu) {
                headerDropdownMenues.push(menu);
                element.addEventListener('click', (event: MouseEvent) => {
                    event.preventDefault();
                    headerDropdownMenues.forEach(m => {
                        if (m !== menu) {
                            const collapse = window.bootstrap.Collapse.getInstance(m);
                            if (collapse) {
                                collapse.hide();
                            }
                        }
                    })
                    const collapse = window.bootstrap.Collapse.getOrCreateInstance(menu);
                    collapse.toggle();
                });
                element.addEventListener('mouseover', () => {
                    headerDropdownMenues.forEach(m => {
                        if (m !== menu) {
                            m.style.zIndex = "1";
                        }
                    })
                    menu.style.zIndex = "2";
                });
                element.addEventListener('keydown', (event: KeyboardEvent) => {
                    event.preventDefault();
                    const keycode = event.keyCode || event.which;
                    if (keycode == 13) {
                        headerDropdownMenues.forEach(m => {
                            if (m !== menu) {
                                const collapse = window.bootstrap.Collapse.getInstance(m);
                                if (collapse) {
                                    collapse.hide();
                                }
                            }
                        })
                        const collapse = window.bootstrap.Collapse.getOrCreateInstance(menu);
                        collapse.toggle();
                    }
                });
            }
        }
    });
}

document.addEventListener('DOMContentLoaded', function () {
    initHeaderInterSection();
    initHeaderSearch();
    initHeaderKeyboardMenu();
});
