document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('[data-toggle-class]')
		.forEach((element: HTMLElement) => {
			element.addEventListener('click', (e) => {
				const targetQuery = element.dataset.toggleClassTarget;
				const className = element.dataset.toggleClass;
				if (targetQuery && className) {
					const target: HTMLElement | null = document.querySelector(targetQuery);
					if (target) {
						target.classList.toggle(className);
						e.stopPropagation();
					}
				}
			});
		});
});
