const getMetaValue = (name: string): string | null | undefined => {
    const elem = document.querySelector('meta[name=\'' + name + '\']');
    if (elem) {
        return elem.getAttribute('content');
    }
}

const initMatomo = () => {
    const id = getMetaValue('matomo:site_id');
    const lang = getMetaValue('matomo:site_lang') || 'en';
    const area = getMetaValue('matomo:dimension_area');
    const group = getMetaValue('matomo:dimension_group');
    const impression = getMetaValue('matomo:content_impression');
    const is404 = getMetaValue('matomo:dimension_404');
    if (!id) {
        return;
    }
    const _paq = window._paq = window._paq || [];
    _paq.push(['setDoNotTrack', true]);
    _paq.push(['disableCookies']);
    const customDimension: { [key: string]: string } = {dimension2: lang};
    if (area) {
        customDimension.dimension4 = area;
    }
    if (group) {
        customDimension.dimension5 = group;
    }
    if (is404) {
        customDimension.dimension6 = document.location.pathname;
        const title = `404/URL = ${encodeURIComponent(document.location.pathname + document.location.search)}`
            + (document.referrer ? ` - From = ${encodeURIComponent(document.referrer)}` : '');
        _paq.push(['setDocumentTitle', title]);
    }
    // tracker methods like "setCustomDimension" should be called before "trackPageView" OR be set to be used for page view ONLY:
    // https://developer.matomo.org/guides/tracking-javascript-guide#tracking-a-custom-dimension-across-tracking-requests
    _paq.push(['trackPageView', document.title, customDimension]);
    _paq.push(['enableLinkTracking']);
    if (impression) {
        _paq.push(['trackVisibleContentImpressions']);
    }
    _paq.push(['setTrackerUrl', 'https://openproject.matomo.cloud/matomo.php']);
    _paq.push(['setSiteId', id]);

    const g = document.createElement('script');
    g.type = 'text/javascript';
    g.defer = true;
    g.async = true;
    g.src = 'https://openproject.matomo.cloud/matomo.js';
    document.body.appendChild(g);
}

const registerMenuLinkTracking = (query, id) => {
    document.querySelectorAll(query).forEach((element: HTMLAnchorElement) => {
        element.addEventListener('click', () => {
            const paq = window._paq;
            if (paq) {
                paq.push(['trackEvent', 'menu', 'click', id]);
            }
        });
    });
}

const registerMenuSignInButtonTracking = () => {
    registerMenuLinkTracking('.header-item-signin a', 'header-sign-in');
    registerMenuLinkTracking('.header-item-install a', 'header-install');
}

document.addEventListener('DOMContentLoaded', function () {
    initMatomo();
    registerMenuSignInButtonTracking()
});
